<template>
  <MainViewLayout
    v-if="renderComponent"
    data-test-id="booking-summary-layout"
  >
    <template #main-content>
      <FeedbackModalComponent
        v-bind="modals.success"
        :is-open="modals.success.isOpen"
      />

      <InsuranceInfoModal
        v-bind="modals.insuranceInfo"
        :is-open="modals.isOpen"
        @on:close="() => modals.insuranceInfo.isOpen = false"
      />
      <div class="MapViewLayout LayoutPage">
        <div class="Booking">
          <div
            v-show="renderComponent"
            class="Booking__vehicleColumn"
          >
            <div class="Booking__separator align-items-baseline mb-0 pb-0 d-flex">
              <div class="d-flex my-4 mx-6">
                <ui-icon
                  :icon="ICONS.arrowBack"
                  :size="ICONS_SIZES.small"
                  :color="GRAYSCALE.ink"
                  hover
                  class="mx-2 cursor-pointer"
                  alt="Go back"
                  @click="goBack"
                />
                <h2>{{ $t("views.booking.title") }}</h2>
              </div>
            </div>

            <div class="Booking__content px-6 pb-6">
              <div class="phLocation mt-5 pt-0">
                <RecurringBookingSummary
                  v-if="size(bookingPeriods)"
                  :periods="bookingPeriods"
                  @update:periods="periods => periodsSelected = periods"
                />
                <LocationHeaderMyBookingComponent
                  :locations="headerAddress"
                  :dates="headerDates"
                />
                <AlertComponent
                  v-if="isOutsideWorkingHours"
                  :type="ALERT_TYPES.warning"
                  class="w-100 mb-3"
                  data-test-id="alert-outside-working-hours"
                >
                  {{ $t('new_booking.alerts.outside_working_hours') }}
                </AlertComponent>
                <VehicleCategoryComponent
                  v-if="reservation.isPreBooking"
                  v-bind="reservation"
                  :map-manager="mapManager"
                  expanded
                />
                <VehicleBookingSummary
                  v-else
                  v-bind="reservation"
                  :booking="bookedTime"
                />
              </div>
            </div>
          </div>

          <div
            v-if="renderComponent"
            class="Booking__summaryColumn"
          >
            <BookingSummaryFormComponent
              ref="summary"
              :map-manager="mapManager"
              :is-confirm-disabled="isFormDisabled"
              :are-cost-allocations-loading="areCostAllocationsLoading"
              :loading="isFormLoading"
              :is-recurring-booking="size(bookingPeriods) > 0"
              :booking-user-uuid="bookingUserUuid"
              @create-booking="onClickCreateReservation"
              @create-booking-request="onClickCreateRequestBooking"
            />
          </div>
        </div>
      </div>
    </template>
  </MainViewLayout>
</template>
<script>
// NPM
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import delay from 'lodash/delay';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import pick from 'lodash/pick';
import size from 'lodash/size';
import clone from 'lodash/clone';
import isNil from 'lodash/isNil';
import each from 'lodash/each';
import { useGtm } from '@gtm-support/vue2-gtm';

// Utils
import { external } from '@emobg/web-api-client';
import {
  camelCaseKeys, DATE_FORMAT, DELAY, getValue, isFalsyString, navigationErrorHandler,
} from '@emobg/web-utils';
import { scrollTo } from '@/utils/scrollTo';
import { addCleanedCost, getEmitPayload } from '@/helpers/userCompany/costAllocationsHelpers';
import { getRequestCode, removeRequestCode } from '@/helpers/costAllocation/costAllocationHelpers';
import { bookingConfirmationModalParams } from '@/helpers/booking/bookingSummaryHelpers';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
// Components
import MainViewLayout from '@/templates/MainLayout/MainLayout';
import VehicleBookingSummary from '@/vue/components/organism/Vehicle/VehicleBookingSummary';
import BookingSummaryFormComponent from '@/vue/components/organism/BookingSummary/BookingSummaryFormComponent';
import InsuranceInfoModal from '@/components/Modals/InsuranceInfoModal/InsuranceInfoModal';
import VehicleCategoryComponent from '@/vue/components/organism/Vehicle/VehicleCategorySummary';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import LocationHeaderMyBookingComponent from '@/components/Location/LocationHeader/LocationHeaderMyBookingComponent';
import AlertComponent from '@/components/Alert/AlertComponent';
import RecurringBookingSummary from '@Bookings/Summary/components/RecurringBookingSummary/RecurringBookingSummary';
// Composable
import { useAvailability } from '@/composable/Availability/useAvailability';
import { useInsurance } from '@/composable/Insurance/insurance';

// Mixin
import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';
import EventHandlerMixin from '@/mixins/EventHandler';
import NotificationMixin from '@/mixins/Notifications';

// Services
import Api from '@/vue/api/backoffice';
// Store
import * as BookingModule from '@/vue/stores/Booking/BookingStore';
import { MapManager } from '@/vue/managers/MapManager';
import * as DateTimeModule from '@/vue/stores/DateTime/DateTimeStore';
import {
  ACTIONS as VehicleUserActions,
  GETTERS as VehicleUserGetters,
  MUTATIONS as VehicleUserMutations,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';
import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';
import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';
import {
  ACTIONS as OperatorLocationsActions,
  NAMESPACE as OperatorLocationsNamespace,
} from '@/stores/OperatorLocations/OperatorLocationsModule';
import * as CostAllocationModule from '@/vue/stores/BookingSummary/CostAllocations';
import { fetchCSOperator, getCurrentCSOperator, operatorHasOneWay } from '@/stores/CSOperator/CSOperatorMapper';
// Routes
import BookingRoutesNames from '@Bookings/router/routes-names';
import CompanyAdminRoutesNames from '@Profile/Business/CompanyAdmin/router/routes-names';
// Constants
import { ADDITIONAL_DRIVERS_FILTER, ADDITIONAL_PASSENGERS_FILTER } from '@/constants/vehicleUserTypes.const';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { headerAddress, headerDates } from '@Bookings/Summary/constants/bookingSummary';
import { companyAllowsAdditionalDriver, companyUuid } from '@/stores/Company/CompanyMapper';
import { userUuid } from '@/stores/User/UserData/UserDataMapper';
import { setLoaderStatus } from '@Shared/store/Loader/LoaderMapper';
import { canUseCostAllocation } from '@/stores/User/Profile/ProfileMapper';
import { composeBookingCreation } from '@Bookings/Summary/helpers/gtm';
import { composeBookingCreationEvent } from '@Bookings/Summary/helpers/segment';
import {
  bookedTime,
  bookingDestinationLocationUuid,
  bookingInsurance,
  bookingLocationUuid,
  bookingOperatorUuid,
  bookingPeriods,
  bookingSummary,
  bookingSummaryTotalPrice,
  bookingUserUuid,
  bookingVehicleCategoryUuid,
  createBookingRequestData,
  driverBookingOnBehalfProfileUuid,
  driverProfileUuid,
  isBooking,
  isBookingLocationOpen,
  isBookingOnBehalf,
  isOutsideWorkingHours,
  reservation,
} from '@/stores/Booking/BookingMapper';
import { GTMEvents, GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { mapLocationData } from '@/helpers/user/tracking/ecommerceEvents';
import { getCurrentCity } from '@/stores/City/CityMapper';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';
import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';

export default {
  name: 'BookingSummary',
  components: {
    BookingSummaryFormComponent,
    LocationHeaderMyBookingComponent,
    MainViewLayout,
    VehicleBookingSummary,
    VehicleCategoryComponent,
    FeedbackModalComponent,
    AlertComponent,
    RecurringBookingSummary,
    InsuranceInfoModal,
  },
  mixins: [
    EventHandlerMixin,
    AuthenticatedUserMixin,
    NotificationMixin,
  ],

  beforeRouteLeave(to, from, next) {
    /**
       * When we leave the booking summary, set "visitedCsOperator"
       * to null so the returned value it will be the user operator.
       * */
    this.setVisitedCsOperator(null);
    next();
  },

  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
    auth: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(_props, context) {
    const gtm = useGtm();
    const { resetAvailabilityStore } = useAvailability();
    const { getTranslatedDescriptions } = useInsurance();
    const { notifyError } = useNotifications();
    const {
      trackCartEvent,
      trackEcommerceEvent,
      trackModalView,
      trackPageView,
    } = useTrackingGTM();

    const { storeData } = commonImplementationHelper(context);

    const { getPaymentMethodsCollection } = PaymentMethodsHubComposable(storeData);

    return {
      getTranslatedDescriptions,
      gtm,
      resetAvailabilityStore,
      trackCartEvent,
      trackEcommerceEvent,
      trackModalView,
      trackPageView,
      notifyError,
      getPaymentMethodsCollection,
    };
  },

  data() {
    return {
      mapManager: new MapManager(),
      renderComponent: true,
      isFormLoading: false,
      isFormDisabled: false,
      areCostAllocationsLoading: false,
      modals: {
        success: {
          isOpen: false,
        },
        insuranceInfo: {
          isOpen: false,
        },
      },
      periodsSelected: [],
    };
  },

  computed: {
    companyAllowsAdditionalDriver,
    companyUuid,
    userUuid,
    operatorHasOneWay,
    canUseCostAllocation,
    bookedTime,
    createBookingRequestData,
    isBooking,
    isBookingOnBehalf,
    isOutsideWorkingHours,
    isBookingLocationOpen,
    reservation,
    bookingSummary,
    bookingSummaryTotalPrice,
    bookingInsurance,
    driverProfileUuid,
    driverBookingOnBehalfProfileUuid,
    bookingUserUuid,
    bookingLocationUuid,
    bookingDestinationLocationUuid,
    bookingVehicleCategoryUuid,
    bookingPeriods,
    bookingOperatorUuid,
    getCurrentCSOperator,
    getCurrentCity,

    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      {
        selectedCostAllocations: state => state.COST_ALLOCATION_DATA.currentCostAllocations,
      },
    ),
    ...mapGetters(
      BookingCostAllocationModule.NAMESPACE,
      {
        costAllocationPayload:
            CostAllocationDataModule.GETTERS.apiPayloadCurrentCostAllocation,
      },
    ),
    ...mapState(
      CostAllocationModule.nameSpace,
      {
        costs: state => get(state, 'cost.data.data'),
      },
    ),

    ...mapGetters(
      VehicleUserNamespace,
      {
        getVehicleUsers: VehicleUserGetters.data,
      },
    ),

    showAdditionalDrivers() {
      return !this.isBookingLocationOpen && this.companyAllowsAdditionalDriver;
    },
  },

  async created() {
    this.areCostAllocationsLoading = this.canUseCostAllocation;

    this.ADDITIONAL_PASSENGERS_FILTER = ADDITIONAL_PASSENGERS_FILTER;
    this.ADDITIONAL_DRIVERS_FILTER = ADDITIONAL_DRIVERS_FILTER;

    this.ALERT_TYPES = ALERT_TYPES;

    this.resetVehicleUsers();

    // When isBooking or hash is false is because the user
    // has visit the booking summary page directly
    // from the URL, not from Home, making a booking
    const { hash } = this.$route.params;
    if (!this.isBooking && !hash) {
      this.renderComponent = false;
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
      return;
    }

    // Does isBooking variable exist?
    // No! Ok, doesn't matter, hash variable exists.
    // In this case, we have to check if this hash
    // is right or not.
    if (!this.isBooking) {
      this.renderComponent = false;
      const bookingData = window.sessionStorage.getItem(`booking_${hash}`);
      // If bookingData does not exist, hash is incorrect and we have to redirect
      // to home page
      if (!bookingData) {
        this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
        return;
      }

      const {
        reservation: reservationData,
        bookingSummary: bookingSummaryData,
        started,
      } = JSON.parse(bookingData);

      // When bookingData exists, we have to restore the State
      await this.setReservation(reservationData);
      await this.setSummary(bookingSummaryData);
      await this.startBooking({ started });

      this.handleOperatorHasOneWay(reservationData);

      this.requestGetVehicleDetails(bookingSummaryData, reservationData);
    }

    this.periodsSelected = clone(this.bookingPeriods);

    if (this.canUseCostAllocation) {
      const userProfileUuid = this.isBookingOnBehalf ? get(this.reservation, 'driver.business_profile_uuid') : undefined;
      const requestCode = getRequestCode();

      this.areCostAllocationsLoading = true;
      await this.getCostAllocations({
        companyUuid: this.companyUuid,
        payload: {
          filter: 'booking',
          ...(userProfileUuid ? { user_profile_uuid: userProfileUuid } : {}),
        },
      });

      if (requestCode) {
        const response = await this.getExternalBooking({
          companyUuid: this.companyUuid,
          payload: {
            token: requestCode,
          },
        });

        // In case BE throws an error, RunAsyncFlow returns undefined
        // Remove request_code and data to avoid a loop of errors
        if (!response) {
          removeRequestCode();
          this.setExternalBooking(null);
          this.deleteExternalBooking();
          this.areCostAllocationsLoading = false;
          return;
        }

        const {
          company_cost_allocation_uuid: costAllocationUuid,
          external_booking_id: externalBookingId,
        } = response;
        const cost = find(this.costs, { uuid: costAllocationUuid });
        const payload = getEmitPayload(cost, externalBookingId);
        const selectedCosts = addCleanedCost(this.selectedCostAllocations, payload);
        this.setCurrentCostAllocations(selectedCosts);
      }
    }
    this.renderComponent = true;
    this.areCostAllocationsLoading = false;
    this.scrollTo();
    this.setLoaderStatus(false);
    this.eventHandler.$on(
      this.events.OPEN_INSURANCE_MORE_INFO_MODAL,
      this.openInsuranceMoreInfoModal,
    );
  },

  beforeMount() {
    this.headerAddress = headerAddress({ reservation: this.reservation });
    this.headerDates = headerDates({ reservation: this.reservation });
  },

  async mounted() {
    const currencyCode = await this.getCurrencyCode();
    this.trackCartEvent({
      bookingData: this.reservation,
      city: this.getCurrentCity,
      cityFallback: this.bookingSummary,
      currency: currencyCode,
      location: mapLocationData(get(this, 'reservation.location')),
      vehicles: [getValue(this, 'reservation.vehicle')],
      discount: getValue(this, 'bookingSummary.price.promotions_subtotal_raw'),
      price: getValue(this, 'bookingSummary.price.net_total_raw'),
      eventName: GTMEvents.beginCheckout,
    });
  },

  beforeDestroy() {
    this.eventHandler.$off(this.events.OPEN_INSURANCE_MORE_INFO_MODAL);
  },

  methods: {
    size,
    scrollTo,
    setLoaderStatus,
    fetchCSOperator,

    ...mapActions(CostAllocationModule.nameSpace, {
      getCostAllocations: CostAllocationModule.ACTIONS.getCostAllocations,
      getExternalBooking: CostAllocationModule.ACTIONS.getExternalBooking,
      deleteExternalBooking: CostAllocationModule.ACTIONS.deleteExternalBooking,
    }),

    ...mapMutations(BookingModule.nameSpace, {
      resetBookingState: BookingModule.MUTATIONS.RESET_STATE,
      setReservation: BookingModule.MUTATIONS.SET_RESERVATION,
      setSummary: BookingModule.MUTATIONS.SET_SUMMARY,
      startBooking: BookingModule.MUTATIONS.START_BOOKING,
    }),

    ...mapMutations(DateTimeModule.nameSpace, {
      resetDateTimeState: DateTimeModule.MUTATIONS.RESET_STATE,
    }),

    ...mapMutations(VehicleUserNamespace, {
      resetVehicleUsers: VehicleUserMutations.removeAll,
    }),

    ...mapActions(
      VehicleUserNamespace,
      {
        updateVehicleUserDrivers: VehicleUserActions.updateDrivers,
      },
    ),

    // TODO: Temp fix, use the same store: https://europcarmobility.atlassian.net/browse/CBF-882
    ...mapMutations(
      BookingCostAllocationModule.NAMESPACE,
      {
        setExternalBooking:
            BookingCostAllocationModule.MUTATIONS.setExternalBooking,
        setCurrentCostAllocations:
          CostAllocationDataModule.MUTATIONS.setCurrentCostAllcation,
      },
    ),

    ...mapActions(OperatorLocationsNamespace, {
      fetchOperatorLocations: OperatorLocationsActions.fetchOperatorLocations,
    }),

    async goBack() {
      const currencyCode = await this.getCurrencyCode();

      this.trackCartEvent({
        bookingData: this.reservation,
        city: this.getCurrentCity,
        cityFallback: this.bookingSummary,
        currency: currencyCode,
        location: mapLocationData(get(this, 'reservation.location')),
        vehicles: [get(this, 'reservation.vehicle')],
        discount: get(this, 'bookingSummary.price.promotions_subtotal_raw'),
        price: get(this, 'bookingSummary.price.net_total_raw'),
        eventName: GTMEvents.removeFromCart,
      });

      this.$router.back();
      this.startBooking({ started: false });
    },

    async onClickCreateReservation() {
      this.isFormLoading = true;
      this.isFormDisabled = true;

      const passengersData = this.getVehicleUsers(this.ADDITIONAL_PASSENGERS_FILTER);
      const passengers = map(passengersData, 'data');
      const costAllocations = size(this.costAllocationPayload) ? this.costAllocationPayload : [];

      const reservationRequestParameters = {
        ...this.createBookingRequestData(),
        passengers,
        company_cost_allocations: costAllocations,
      };

      await Api.carsharing.setBooking(reservationRequestParameters)
        .catch(error => {
          this.isFormLoading = false;
          this.isFormDisabled = false;
          this.bookingCanNotBeCreatedStep(error);
        })
        .then(this.bookingCreatedStep)
        .then(this.additionalDriverStep)
        .then(this.bookingFinishedStep)
        .catch(error => {
          this.isFormLoading = false;
          this.isFormDisabled = false;
          this.notifyError({
            text: error.message,
            textAction: this.$t('modal.booking_confirm.ok'),
          });
        });
    },

    async onClickCreateRequestBooking() {
      this.isFormLoading = true;
      this.isFormDisabled = true;

      // TODO: Replace this for helper in src/helpers/booking/apiHelpers.js
      const costAllocations = size(this.costAllocationPayload) ? this.costAllocationPayload : [];
      const passengersData = this.getVehicleUsers(this.ADDITIONAL_PASSENGERS_FILTER);
      const passengers = map(passengersData, 'data');

      const driversEmails = this.getVehicleUsers(this.ADDITIONAL_DRIVERS_FILTER);
      const drivers = this.showAdditionalDrivers && size(driversEmails)
        ? map(driversEmails, 'data')
        : [];

      const locationUuid = this.bookingLocationUuid;
      const destinationLocationUuid = this.bookingDestinationLocationUuid;

      const isRecurrentBooking = size(this.periodsSelected);
      const bookingTimes = isRecurrentBooking
        ? { periods: this.periodsSelected }
        : { ...pick(this.reservation, ['start', 'end']) };

      const payload = {
        ...pick(this.reservation, ['comment']),
        ...bookingTimes,
        operator_id: this.bookingOperatorUuid,
        user_uuid: this.bookingUserUuid,
        user_profile_uuid: this.isBookingOnBehalf
          ? this.driverBookingOnBehalfProfileUuid
          : this.driverProfileUuid,
        vehicle_category_uuid: this.bookingVehicleCategoryUuid,
        location_uuid: locationUuid,
        destination_location_uuid: destinationLocationUuid || locationUuid,
        company_cost_allocations: costAllocations,
        passengers,
        drivers,
      };

      let prebookingResponse;
      try {
        const createPrebookingMethod = isRecurrentBooking
          ? external.preBooking.postRecurrentPreBooking
          : external.preBooking.postPreBooking;
        const response = await createPrebookingMethod(payload);
        prebookingResponse = get(response, 'data');
      } catch (error) {
        const bookingCreationError = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({
          text: bookingCreationError,
          textAction: this.$t('modal.booking_confirm.ok'),
        });
        this.trackBookingCreation({ bookingCreated: false, bookingCreationError });
      } finally {
        this.isFormLoading = false;
        this.isFormDisabled = false;
        if (get(prebookingResponse, 'uuid') || size(get(prebookingResponse, 'requested'))) {
          delay(() => this.bookingCreatedStep(prebookingResponse), DELAY.extraLong);
          const isPrebookingRightNow = !isFalsyString(get(prebookingResponse, 'bookingUuid'));
          this.bookingFinishedStep({ ...prebookingResponse, isPrebookingRightNow, isRecurrentBooking });
        }
      }
    },

    openInsuranceMoreInfoModal() {
      if (!this.bookingInsurance) {
        return;
      }

      const insurances = [this.bookingInsurance.current_insurance]
        .concat(this.bookingInsurance.upsell_insurances);
      const operatorName = get(this, 'reservation.operator.name');
      const insurancesTranslated = this.getTranslatedDescriptions(operatorName, insurances);

      this.modals.insuranceInfo = {
        ...this.modals.insuranceInfo,
        isOpen: true,
        insurancesInfo: insurancesTranslated,
      };
      this.trackModalView({ modalName: GTMModalEvents.bookingSummaryInsurancesInfo });
    },

    confirmReservation(bookingCreation = {}) {
      const { isPrebookingRightNow } = bookingCreation;

      let route = { name: BookingRoutesNames.myBookings };
      if (this.isBookingOnBehalf) {
        route = this.reservation.isPreBooking && !isPrebookingRightNow
          ? { name: CompanyAdminRoutesNames.employeeBookingRequests }
          : { name: CompanyAdminRoutesNames.employeeBookingsList };
      }

      this.$router.push(route, () => {
        this.resetBookingState();
        this.resetAvailabilityStore();
        this.resetDateTimeState();
      });
    },

    onChangeProfile() {
      this.$router.replace({ name: BookingRoutesNames.home }).catch(navigationErrorHandler);
    },

    async trackBookingCreation(params) {
      const {
        bookingCreated, bookingCreationError, bookingUuid, recurrentBookings,
      } = params;
      const currencyCode = await this.getCurrencyCode();

      const eventData = composeBookingCreationEvent({
        reservation: this.reservation,
        bookingSummary: this.bookingSummary,
        created: bookingCreated,
        errorMessage: bookingCreationError,
        bookingUuid,
        currencyCode,
      });

      this.gtm.trackEvent({
        event: GTMEvents.carsharingBookingCreated,
        event_data: eventData,
      });

      if (bookingCreated) {
        const commonEventData = composeBookingCreation({
          ...camelCaseKeys(pick(this.bookingSummary, ['price', 'booking', 'payment_method', 'location'])),
          ...pick(this.reservation, ['vehicle', 'profile', 'insurance']),
          currencyCode,
          bookingUuid,
        });

        if (!isNil(bookingUuid)) {
          this.gtm.trackEvent({
            event: GTMEvents.carsharingBookingCreated,
            event_data: commonEventData,
          });
        }

        if (size(recurrentBookings)) {
          each(recurrentBookings, ({ uuid }) => {
            this.trackEcommerceEvent({
              eventName: GTMEvents.purchase,
              eventData: {
                ...commonEventData,
                transactionId: uuid,
              },
            });
          });
        }
      }
    },

    bookingFinishedStep(bookingCreation = {}) {
      const location = get(bookingCreation, 'location', {});
      const errors = get(bookingCreation, 'errors');
      const { isRecurrentBooking } = bookingCreation;

      this.modals.success = {
        ...bookingConfirmationModalParams(location, this.reservation.isPreBooking, isRecurrentBooking, errors),
        primaryCallToAction: () => this.confirmReservation(bookingCreation),
      };

      this.isFormLoading = false;
      this.isFormDisabled = false;
      this.modals.success.isOpen = true;
      this.trackModalView({ modalName: GTMModalEvents.bookingCreated });
    },

    bookingCanNotBeCreatedStep(errorResponse) {
      // TODO: Replace this for apiHelper for handling errors
      const errorKey = get(errorResponse, 'response.data.key');
      const errorMessage = get(errorResponse, 'response.data.message');
      const genericError = this.$t('carsharing_personal.error.booking_no_created');

      const bookingCreationError = errorKey
        ? this.$t(`carsharing_personal.error.${errorKey}`, errorMessage || genericError)
        : errorMessage || genericError;

      this.trackBookingCreation({ bookingCreated: false, bookingCreationError });

      throw new Error(bookingCreationError);
    },

    bookingCreatedStep(bookingResponse) {
      this.trackBookingCreation({
        bookingCreated: true,
        bookingUuid: get(bookingResponse, 'uuid'),
        recurrentBookings: get(bookingResponse, 'requested'),
      });

      removeRequestCode();
      this.setExternalBooking(null);
      this.deleteExternalBooking();

      return { response: get(bookingResponse, 'data') };
    },

    additionalDriverStep(bookingCreation) {
      const driversEmails = this.getVehicleUsers(this.ADDITIONAL_DRIVERS_FILTER);
      const emails = map(driversEmails, 'data');
      const shouldUpdateDrivers = this.showAdditionalDrivers && size(emails);
      const payload = { bookingUuid: get(bookingCreation, 'response.uuid'), emails };

      return shouldUpdateDrivers
        ? this.updateVehicleUserDrivers(payload)
          .then(() => bookingCreation)
          .catch(error => {
            const errorKey = get(error, 'response.data.key');
            const defaultError = this.$t('carsharing_personal.error.default_additional_drivers');
            return {
              alert: this.$t(`carsharing_personal.error.${errorKey}`, defaultError),
              ...bookingCreation,
            };
          })
        : Promise.resolve(bookingCreation);
    },

    /**
     * Checks wether the operator has one way, in which case, it fetches it's locations
     * @param {object} reservation
     */
    handleOperatorHasOneWay(reservationData) {
      if (!this.operatorHasOneWay(this.bookingOperatorUuid)) {
        return;
      }

      const end = moment(get(reservationData, 'end', null));
      const query = end.isValid() ? { booking_date: end.format(DATE_FORMAT.filter) } : null;

      this.fetchOperatorLocations({
        operatorUuid: this.bookingOperatorUuid,
        query,
      });
    },

    /**
     * Requests the vehicle details and sets them in the state if there's a vehicle uuid in the provided booking summary
     * @param {object} bookingSummary
     * @param {object} reservation
     */
    requestGetVehicleDetails(bookingSummaryData, reservationData) {
      const vehicleUuid = get(bookingSummaryData, 'vehicle.uuid');

      if (vehicleUuid) {
        external.fleetVehicles.getVehicleDetails(vehicleUuid)
          .then(data => {
            this.setReservation({
              vehicle: {
                ...get(bookingSummaryData, 'vehicle', {}),
                ...get(reservationData, 'vehicle', {}),
                ...data,
              },
            });
          });
      }
    },

    async getCurrencyCode() {
      const operator = await this.fetchCSOperator(this.bookingOperatorUuid);

      return get(operator, 'configuration.currency_code');
    },
  },
};
</script>
